import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import './style.scss';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
  updateActionPlan,
  getActionPlan,
  deleteActionPlan,
  getActionTasksPagination,
  updateActionTask,
} from '../../../api/actionPlan';
import { useSystemTogglesContext } from '../../../context/SystemToggles';
import {
  DashboardEmptyButton,
  StyledTrashButton,
} from '../../../styling/buttons';
import { CustomTextFieldActionPlanName } from '../../../styling/generalStyling';
import PageNotFound from '../../../templates/PageNotFound';
import { ActionPlanType, CardsType } from '../../../types';
import { capitalizeFirstLetter } from '../../../utils/text';
import { getUrlParam } from '../../../utils/url';
import DeleteCardPopup from '../deleteCardPopup';
import DeletePlanPopup from '../deletePlanPopup';
import MentorsPopup from '../mentorsPopup';
import { formatDate } from '../utils';
import EditCardMobilePopup from './editCardMobilePopup';
import ExistingCardMobile from './existingCardMobile';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import NewCardMobile from './newCardMobile';

const PlanDetailsMobile = () => {
  const { toggles } = useSystemTogglesContext();
  const showActionPlan = toggles['ACTION_PLAN'];
  const planId = getUrlParam('planId')?.toString() || '';
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [planDetails, setPlanDetails] = useState<ActionPlanType | undefined>(
    undefined
  );
  const [changedOwnership, setChangedOwnership] = useState<boolean>(false);
  const [changeTitle, setChangeTitle] = useState<boolean>(false);
  const [openSharePopup, setOpenSharePopup] = useState(false);
  const [cardDetails, setCardDetails] = useState<CardsType | undefined>();
  const [tasks, setTasks] = useState<CardsType[] | undefined>(undefined);
  const [ticketStatus, setTicketStatus] = useState<string | undefined>(
    undefined
  );
  const handleEditPlanName = () => {
    setChangeTitle(true);
  };
  const [cardEdit, setCardEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteCardPopup, setDeleteCardPopup] = useState<boolean>(false);
  const [deleteCardId, setDeleteCardId] = useState<number | undefined>(
    undefined
  );
  const [actionPlanName, setActionPlanName] = useState('');
  const [counter, setCounter] = useState<number>(0);
  const setCounterChange = () => {
    setCounter(counter + 1);
  };
  const allowOwnerActions =
    planDetails?.my_permission === 'owner' ? true : false;
  const allowEditorActions =
    planDetails?.my_permission === 'owner' ||
    planDetails?.my_permission === 'editor'
      ? true
      : false;
  useEffect(() => {
    if (open === false) {
      setCardEdit(false);
      setOpen(false);
      setTicketStatus(undefined);
      setCardDetails(undefined);
    }
  }, [open]);
  const savePlanTitle = async () => {
    try {
      if (planDetails)
        await updateActionPlan(actionPlanName, planDetails?.id, 'open');
    } catch (error) {
      console.error('Error creating action plan:', error);
    } finally {
      setChangeTitle(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getActionPlan(planId);
        if (result.success) {
          setPlanDetails(result.plan?.[0]);
          setActionPlanName(result.plan?.[0].name);
        } else {
          console.log('error');
        }
      } catch (error) {
        console.log('error', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [planId, changedOwnership]);

  const openDeleteCardPopup = (cardId: number) => {
    setDeleteCardId(cardId);
    setDeleteCardPopup(true);
  };
  const deletePlan = async () => {
    await deleteActionPlan(planDetails?.id.toString() || '')
      .then((response) => {
        if (response.success) {
          window.location.reload();
        } else {
          console.error('Error deleting plan:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error while deleting plan:', error);
      });
  };

  useEffect(() => {
    const fetchAllTasks = async () => {
      let allTasks: CardsType[] = [];
      let nextUrl = '';

      try {
        while (nextUrl !== null) {
          const result = await getActionTasksPagination(planId, 10, nextUrl);
          if (result.success) {
            allTasks = [...allTasks, ...result.tasks];
            nextUrl = result.pagination?.nextUrl;
          } else {
            console.log('Error fetching data');
            break;
          }
        }
        setTasks(allTasks);
      } catch (error) {
        console.log('Error:', error);
      }
    };

    fetchAllTasks();
  }, [counter, planId]);

  const [deletePlanPopup, setDeletePlanPopup] = useState(false);
  const handleAddNewCard = (status: string) => {
    setTicketStatus(status);
    setOpen(true);
  };
  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setActionPlanName(event.target.value);
  };
  const handleEditCard = (card: CardsType) => {
    setCardDetails(card);
    setCardEdit(true);
  };
  const handleUpdateActionTask = async (card: CardsType, status: string) => {
    await updateActionTask(card, status)
      .then((response) => {
        if (response.success) {
          setCounterChange();
        } else {
          console.error('Error updating task:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error in task:', error);
      });
  };
  if (loading)
    return (
      <div className="center-in-page">
        <img
          width="75px"
          height="75px"
          src={'/loading-icon.svg'}
          alt="loading-icon"
        />
      </div>
    );

  if (showActionPlan && planDetails) {
    return (
      <div className="actionplan-container-mobile">
        <div className="actionplan-topbar-mobile">
          <div className="actionplan-title-page-mobile">Action Plan</div>
        </div>
        <Button
          variant="text"
          sx={{
            textTransform: 'none',
            color: '#000000',
            fontWeight: '800',
            fontFamily: 'Gilroy',
            display: 'inline-flex',
            alignItems: 'center',
            marginBottom: '20px',
          }}
          className="go-back-text"
          onClick={() => {
            navigate('/dashboard/actionplan');
          }}
        >
          <img
            src={'/arrowleft.svg'}
            alt="left arrow button"
            style={{ marginRight: '10px', height: '10px' }}
          />
          <div
            className="table-text"
            style={{ paddingTop: '0', height: 'unset' }}
          >
            back to Action Plans
          </div>
        </Button>
        {planDetails ? (
          <div className="plan-details-container-mobile">
            <div className="row-plan-details-mobile">
              <div className="table-text" style={{ marginLeft: '20px' }}>
                Plan name
              </div>
              <CustomTextFieldActionPlanName
                disabled={!changeTitle}
                value={actionPlanName}
                onChange={handleChange}
                variant="standard"
                multiline
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#000000',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                  },
                  color: ' #333',
                  fontFamily: 'Gilroy',
                  marginLeft: '20px',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: '120%',
                  letterSpacing: '0.18px',
                  height: '53px',
                  textAlign: 'center',
                }}
                inputProps={{
                  maxLength: 50,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              />{' '}
              <div className="divider" />
              <div
                style={{
                  display: 'inline-flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  paddingRight: '20px',
                  paddingLeft: '20px',
                }}
              >
                <div className="mobile-plan-details">
                  <div className="table-text-options-mobile"> Permission</div>
                  <div className="table-text-options-mobile">
                    <LockOpenIcon fontSize="small" />
                    <div>
                      {capitalizeFirstLetter(planDetails?.my_permission)}{' '}
                    </div>
                  </div>
                </div>

                <div className="vertical-divider-mobile" />
                {allowOwnerActions ? (
                  <div className="mobile-plan-details">
                    <div className="table-text-options-mobile"> Visibility</div>
                    <div
                      className="table-text-options-mobile"
                      onClick={() => setOpenSharePopup(true)}
                    >
                      <RemoveRedEyeOutlinedIcon fontSize="small" />
                    </div>
                    <div>Share with others</div>
                  </div>
                ) : (
                  <div className="mobile-plan-details">
                    <div> Visibility</div>
                    <div className="table-text-options-mobile">
                      <VisibilityOffOutlinedIcon />
                    </div>
                    <div> No access</div>
                  </div>
                )}
              </div>
            </div>

            <div className="divider" style={{ width: '300px' }} />
            <div
              className="row-plan-details"
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '20px',
                gridTemplateColumns: '190px 100px 100px',
              }}
            >
              {allowEditorActions ? (
                <div
                  className="card-button"
                  style={{
                    width: '100%',
                    display: 'inline-flex',
                    alignItems: 'center',
                    fontFamily: 'Gilroy',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    lineHeight: '120%',
                    letterSpacing: '0.14px',
                  }}
                >
                  {changeTitle ? (
                    <div
                      style={{
                        backgroundColor: '#d9ead3',
                        padding: '4px',
                        width: '120px',
                        fontWeight: 700,
                        borderRadius: '10px',
                        fontFamily: 'Gilroy',
                        display: 'inline-flex',
                        alignItems: 'center',
                        columnGap: '3px',
                        color: '#333333',
                      }}
                      onClick={() => savePlanTitle()}
                    >
                      <CheckOutlinedIcon fontSize="small" /> Save change
                    </div>
                  ) : (
                    <div
                      style={{
                        padding: '4px',
                        width: '100px',
                        fontFamily: 'Gilroy',
                        fontWeight: 700,
                        borderRadius: '10px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        columnGap: '3px',
                        color: '#333333',
                      }}
                      onClick={() => handleEditPlanName()}
                    >
                      <CreateOutlinedIcon fontSize="small" /> Edit name
                    </div>
                  )}
                </div>
              ) : null}
              {allowOwnerActions ? (
                <DashboardEmptyButton
                  variant="text"
                  onClick={() => setDeletePlanPopup(true)}
                  sx={{
                    justifyContent: 'flex-end',
                    color: '#333333',
                    fontFamily: 'Gilroy',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '120%',
                    letterSpacing: '0.14px',
                    padding: 0,
                    width: '100px',
                  }}
                >
                  <StyledTrashButton sx={{ backgroundColor: '#3a3a3a' }} />{' '}
                  Delete plan
                </DashboardEmptyButton>
              ) : null}
            </div>
            <div className="divider" />

            <div className="status-column-container-mobile">
              <div className="status-column-mobile to-do">
                <div className="class-header-mobile">
                  <div>To do</div>{' '}
                {allowEditorActions?  <DashboardEmptyButton
                    onClick={() => handleAddNewCard('todo')}
                    sx={{
                      fontSize: '15px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontFamily: 'Gilroy',
                      lineHeight: '120%',
                      letterSpacing: '0.15px',
                      justifyContent: 'left',
                      display: 'inline-flex',
                      columnGap: '5px',
                      alignItems: 'center',
                      padding: 0,
                    }}
                  >
                    <img src={'/plus.svg'} alt="plus button" height="15px" />{' '}
                    Create a new task
                  </DashboardEmptyButton>:null}
                </div>

                {tasks
                  ?.filter((card: CardsType) => card.status === 'todo')
                  .map((card) => (
                    <div key={card.id} className="task-card-container">
                      <div
                        className="card-name-displayed"
                        onClick={() => {
                          setOpenCard(true);
                          setCardDetails(card);
                        }}
                      >
                        {' '}
                        {card.title?.length > 25
                          ? `${card.title.slice(0, 25)}...`
                          : card.title}{' '}
                      </div>
                      <div
                        className="card-description"
                        onClick={() => {
                          setOpenCard(true);
                          setCardDetails(card);
                        }}
                      >
                        {card.description?.length > 80
                          ? `${card.description.slice(0, 80)}...`
                          : card.description}
                      </div>

                      <div className="card-buttons">
                        <div className="card-date">
                          <img src={'/deadline-icon.svg'} alt="deadline icon" />{' '}
                          {card.deadline
                            ? formatDate(card.deadline)
                            : 'No deadline set'}
                        </div>
                        {allowEditorActions ? (
                          <div className="card-buttons">
                            <div
                              className="card-button"
                              onClick={() => handleEditCard(card)}
                            >
                              <img src={'/pencil-card.svg'} alt="edit card" />
                            </div>
                            <div
                              className="card-button"
                              onClick={() =>
                                handleUpdateActionTask(card, 'in_progress')
                              }
                            >
                              <img src={'/arrow-card.svg'} alt="move card" />
                            </div>
                            <div
                              className="card-button"
                              onClick={() => openDeleteCardPopup(card.id)}
                            >
                              <img src={'/card-bin.svg'} alt="delete card" />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
              </div>

              <div className="status-column-mobile in-progress">
                <div className="class-header-mobile">
                  <div>In Progress</div>{' '}
                {allowEditorActions?  <DashboardEmptyButton
                    onClick={() => handleAddNewCard('in_progress')}
                    sx={{
                      fontSize: '15px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '120%',
                      letterSpacing: '0.15px',
                      justifyContent: 'left',
                      display: 'inline-flex',
                      columnGap: '5px',
                      alignItems: 'center',
                      padding: 0,
                    }}
                  >
                    <img src={'/plus.svg'} alt="plus button" height="15px" />{' '}
                    Create a new task
                  </DashboardEmptyButton>:null}
                </div>
                {tasks
                  ?.filter((card: CardsType) => card.status === 'in_progress')
                  .map((card) => (
                    <div key={card.id} className="task-card-container">
                      <div
                        className="card-name-displayed"
                        onClick={() => {
                          setOpenCard(true);
                          setCardDetails(card);
                        }}
                      >
                        {' '}
                        {card.title?.length > 25
                          ? `${card.title.slice(0, 25)}...`
                          : card.title}{' '}
                      </div>
                      <div
                        className="card-description"
                        onClick={() => {
                          setOpenCard(true);
                          setCardDetails(card);
                        }}
                      >
                        {card.description?.length > 80
                          ? `${card.description.slice(0, 80)}...`
                          : card.description}
                      </div>

                      <div className="card-buttons">
                        <div className="card-date">
                          <img src={'/deadline-icon.svg'} alt="deadline icon" />{' '}
                          {card.deadline
                            ? formatDate(card.deadline)
                            : 'No deadline set'}
                        </div>
                        {allowEditorActions ? (
                          <div className="card-buttons">
                            <div
                              className="card-button"
                              onClick={() => handleEditCard(card)}
                            >
                              <img src={'/pencil-card.svg'} alt="edit card" />
                            </div>
                            <div
                              className="card-button"
                              onClick={() =>
                                handleUpdateActionTask(card, 'todo')
                              }
                            >
                              <img src={'/right-arrow.svg'} alt="move card" />
                            </div>
                            <div
                              className="card-button"
                              onClick={() =>
                                handleUpdateActionTask(card, 'completed')
                              }
                            >
                              <img src={'/arrow-card.svg'} alt="move card" />
                            </div>
                            <div
                              className="card-button"
                              onClick={() => openDeleteCardPopup(card.id)}
                            >
                              <img src={'/card-bin.svg'} alt="delete card" />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="status-column-mobile completed">
                <div className="class-header-mobile">Completed</div>
                {tasks
                  ?.filter((card) => card.status === 'completed')
                  .map((card) => (
                    <div key={card.id} className="task-card-container">
                      <div
                        className="card-name-displayed"
                        onClick={() => {
                          setOpenCard(true);
                          setCardDetails(card);
                        }}
                      >
                        {' '}
                        {card.title?.length > 25
                          ? `${card.title.slice(0, 25)}...`
                          : card.title}{' '}
                      </div>
                      <div
                        className="card-description"
                        onClick={() => {
                          setOpenCard(true);
                          setCardDetails(card);
                        }}
                      >
                        {card.description?.length > 80
                          ? `${card.description.slice(0, 80)}...`
                          : card.description}
                      </div>
                      <div className="card-buttons">
                        <div
                          className="card-date"
                          style={{
                            textDecoration: card.deadline
                              ? 'line-through'
                              : undefined,
                          }}
                        >
                          <img src={'/deadline-icon.svg'} alt="deadline icon" />{' '}
                          {card.deadline
                            ? formatDate(card.deadline)
                            : 'No deadline set'}
                        </div>
                        {allowEditorActions ? (
                          <div className="card-buttons">
                            <div
                              className="card-button"
                              onClick={() => handleEditCard(card)}
                            >
                              <img src={'/pencil-card.svg'} alt="edit card" />
                            </div>
                            <div
                              className="card-button"
                              onClick={() =>
                                handleUpdateActionTask(card, 'in_progress')
                              }
                            >
                              <img src={'/right-arrow.svg'} alt="move card" />
                            </div>

                            <div
                              className="card-button"
                              onClick={() => openDeleteCardPopup(card.id)}
                            >
                              <img src={'/card-bin.svg'} alt="delete card" />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
              </div>
              <NewCardMobile
                setCounterChange={setCounterChange}
                cardDetails={cardDetails}
                isOpen={open}
                planId={planId}
                status={ticketStatus ? ticketStatus : ''}
                onClose={() => setOpen(false)}
              />
            </div>
            {cardDetails ? (
              <ExistingCardMobile
                setCounterChange={setCounterChange}
                permission={planDetails.my_permission}
                cardDetails={cardDetails}
                isOpen={openCard}
                onClose={() => setOpenCard(false)}
                setCardEdit={setCardEdit}
              />
            ) : null}
            {cardDetails ? (
              <EditCardMobilePopup
                setCounterChange={setCounterChange}
                cardDetails={cardDetails}
                cardEdit={cardEdit}
                onClose={() => setCardEdit(false)}
              />
            ) : null}
            {openSharePopup ? (
              <MentorsPopup
                setChangedOwnership={setChangedOwnership}
                planId={planDetails.id}
                open={openSharePopup}
                handleClose={() => setOpenSharePopup(false)}
              />
            ) : null}

            {deletePlanPopup ? (
              <DeletePlanPopup
                open={deletePlanPopup}
                onClose={() => setDeletePlanPopup(false)}
                handleDeleteSelectedPlan={deletePlan}
                deletePlan={planDetails.id}
              />
            ) : null}
            {deleteCardPopup ? (
              <DeleteCardPopup
                setCounterChange={setCounterChange}
                open={deleteCardPopup}
                onClose={() => setDeleteCardPopup(false)}
                deleteCardId={deleteCardId ? deleteCardId : 0}
              />
            ) : null}
          </div>
        ) : (
          <PageNotFound />
        )}
      </div>
    );
  } else return <PageNotFound />;
};

export default PlanDetailsMobile;
