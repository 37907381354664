import React, { useState, useEffect, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  DashboardEmptyButton,
  DashboardFilledButton,
} from '../../styling/buttons';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import useMessagingCentre from '../../apiHooks/messagingCentre/useMessagingCentre';
import { Modal, Box, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import _ from 'lodash';
import {
  updatePermission,
  deletePermission,
  getActionPlanPermission,
} from '../../api/actionPlan';
import { capitalizeFirstLetter } from '../../utils/text';
import useWindowSize from '../../hooks/useWindowSize';
import UserSelectCard from './MentorSelectCard';
import { CustomTypographyPermission } from '../../styling/generalStyling';
interface MentorsPopupProps {
  open: boolean;
  handleClose: () => void;
  planId: number;
  setChangedOwnership: (value: boolean) => void;
}
type UserType = {
  userId: number | string;
  role: string;
};

const MentorsPopup = ({
  open,
  handleClose,
  planId,
  setChangedOwnership,
}: MentorsPopupProps) => {
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;
  const [pageNewUsers, setPageNewUsers] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sharedOwnership, setSharedOwnership] = useState(false);
  const [selectedUser, setSelectedUser] = useState<undefined | any>(undefined);
  const [showChangePermissionPopup, setShowChangePermissionPopup] =
    useState<boolean>(false);
  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '10px',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '300px' : '500px',
    height: 'auto' ,
    paddingTop: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
    bgcolor: ' #fff',
  };
  const [selectedRole, setSelectedRole] = useState('viewer');
  const [changedPermission, setChangedPermission] = useState<number>(0);
  const [currentPermissions, setCurrentPermissions] = useState<UserType[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserType[]>([]);
  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(event.target.value);
  };

  const updatePermissionAction = async () => {
    if (selectedUser) {
      await updatePermission(selectedUser?.id, selectedRole);
      setChangedPermission(changedPermission + 1);
      setShowChangePermissionPopup(false);
    } else return;
  };
  const deletePermissionAction = async (item: any) => {
    await deletePermission(item.id);
    setChangedPermission(changedPermission + 1);
  };
  const handleUserClick = (userRole: { userId: any; role?: string }) => {
    const isUserSelected = selectedUsers.some(
      (user) => user?.userId === userRole.userId && user?.role === userRole.role
    );

    if (isUserSelected) {
      // Remove the user if they are already in the list
      setSelectedUsers(
        selectedUsers.filter((user) => user?.userId !== userRole.userId)
      );
    } else {
      // Add the user with a default role of 'viewer' if they are not in the list
      setSelectedUsers([...selectedUsers, { ...userRole, role: 'viewer' }]);
    }
  };
  const { availableUsers, hasNextAvailableUsers, getAvailableUsers } =
    useMessagingCentre({});
  const debouncedSearch = useRef(
    _.debounce((value) => getAvailableUsers(value), 500)
  ).current;


  useEffect(() => {
    const getPlanPermission = async () => {
      const response = await getActionPlanPermission(planId);

      setCurrentPermissions(response.permissions);
    };
    getPlanPermission();
  }, [planId, selectedUsers, changedPermission]);

  const closeModal = () => {
    setSelectedUsers([]);
    handleClose();
  };

  if (sharedOwnership && open)
    return (
      <div
        className="new-message__overlay"
        onClick={() => {
          setChangedOwnership(true);
          closeModal();
        }}
      >
        <div
          className="action-plan-popup__body"
          onClick={(e) => e.stopPropagation()}
        >
         Ownership successfully transferred
        </div>
      </div>
    );
  return open ? (
    <div className="new-message__overlay" onClick={closeModal}>
      <div
        className="action-plan-popup__body"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="new-message__header">
          <p>Share plan</p>

          <button onClick={closeModal}>
            <CloseIcon fontSize="small" />
          </button>
        </div>
        <div className="action-plan-search">
          <div>
            <input
              style={{
                border: 'none',
                outline: 'none',
                minWidth: '300px',
                fontFamily: 'Gilroy',
              }}
              placeholder="Search members to share with"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                debouncedSearch(e.target.value);
              }}
            />

            {searchValue && searchValue.length > 0 && (
              <div
                className="search-input-clear-button"
                onClick={() => {
                  setSearchValue('');
                  debouncedSearch('');
                }}
              ></div>
            )}
          </div>
        </div>

        <div id="newUserModalDiv" className="action-plan-mentors__scrollable">
          <InfiniteScroll
            dataLength={4}
            next={() => {
              setPageNewUsers(pageNewUsers + 1);
              getAvailableUsers(undefined, pageNewUsers + 1);
            }}
            hasMore={hasNextAvailableUsers}
            loader={<h4>Loading...</h4>}
            scrollableTarget="newUserModalDiv"
          >
            {availableUsers?.length > 0 ? (
              availableUsers
                ?.slice(0, 3)
                ?.map((item: any, idx: number) => (
                  <UserSelectCard
                    planId={planId}
                    key={idx}
                    id={item?.organisation?.user_id}
                    profilePicture={item?.organisation?.profile_picture}
                    name={item?.organisation?.first_name}
                    setUserToShareWith={(user) => handleUserClick(user)}
                    selectedUsers={selectedUsers}
                    setSharedOwnership={setSharedOwnership}
                  />
                ))
            ) : (
              <div className="new-message__no-users">No users available</div>
            )}
          </InfiniteScroll>
        </div>

        <div className="permission-popup-text">People with access</div>
        <div className="user-given-permission-container">
          {currentPermissions?.length > 0 ? (
            currentPermissions?.map((user: any, idx: number) => (
              <div className="user-given-permission">
                <div className="user-information">
                  <img
                    src={user?.organisation?.profile_picture || 'avatar.png'}
                    alt="User avatar"
                    className="new-mentor-select-card__photo"
                  />
                  <div className="new-mentor-select-card__name">
                    {user?.organisation?.first_name?.length > 10
                      ? `${user?.organisation?.first_name?.slice(0, 10)}...`
                      : user?.organisation?.first_name}
                  </div>
                  <div className="actionplan-tab-text">
                    {capitalizeFirstLetter(user?.permission_type)}
                  </div>
                </div>
                <div className="quick-permission-button-wrapper">
                  <div
                    className="permission-button"
                    onClick={() => {
                      setSelectedUser(user);
                      setShowChangePermissionPopup(true);
                    }}
                  >
                    <CreateOutlinedIcon sx={{ color: 'grey' }} />
                    Change
                  </div>
                  <div
                    className="permission-button"
                    onClick={() => deletePermissionAction(user)}
                  >
                    <img src={'/trash-icon.svg'} alt="delete permissions" />{' '}
                    Delete
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="actionplan-tab-text">No permissions given</div>
          )}
        </div>
        <Modal
          open={showChangePermissionPopup}
          onClose={() => setShowChangePermissionPopup(false)}
        >
          <Box sx={modalStyle}>
            <div className="permission-popup-action-plan">
              Set the permission for this user
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedRole}
                onChange={handleRoleChange}
                name="radio-buttons-group"
                className="user-select-permission"
                style={{ flexDirection: 'row' }}
              >
                <FormControlLabel
                  value="viewer"
                  control={<Radio />}
                  label={
                    <CustomTypographyPermission sx={{ fontFamily:'Gilroy'}}>
                     Viewer
                    </CustomTypographyPermission>
                  }
                  sx={{ width: '100px' }}
                />
                <FormControlLabel
                  value="editor"
                  control={<Radio />}
                  label={
                    <CustomTypographyPermission sx={{ fontFamily:'Gilroy'}}>
                     Editor
                    </CustomTypographyPermission>
                  }
                  sx={{ width: '100px' }}
                />
              </RadioGroup>
              <div className="permission-buttons-wrapper">
                <DashboardFilledButton
                  variant="contained"
                  sx={{ width: isMobile ? '100px' : '189px' }}
                  onClick={updatePermissionAction}
                >
                  Confirm
                </DashboardFilledButton>
                <DashboardEmptyButton
                  onClick={() => setShowChangePermissionPopup(false)}
                  variant="outlined"
                  sx={{ width: isMobile ? '100px' : '189px' }}
                >
                  Cancel
                </DashboardEmptyButton>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  ) : null;
};

export default MentorsPopup;
