import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styling/requests.scss';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import NewInviteCard from '../NewInviteCard';
import NewRequestedCard from '../NewRequestedCard';
import { GroupEventRequest, Taxonomies, Taxonomy } from '../../../types';
import {
  CustomMobileRequestsLabel,
  CustomRequestsLabel,
  LoadMoreButton,
} from '../styling';
import { styled } from '@mui/material/styles';
import {
  Alert,
  Badge,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@mui/material';
import useWindowSize from '../../../hooks/useWindowSize';
import RequestsEmptyPage from './requestsEmptyPage';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import api from '../../../api';
import { useSystemTogglesContext } from '../../../context/SystemToggles';
import NewCallDetails from '../../../pages/NewCallDetails';
import Cookie from 'js-cookie';
import { getAccessToken, loginWithRefreshToken } from '../../../utils/auth';
import CommunityRequestsEmptyPage from './communityRequestsEmptyPage';
import { CheckboxCustomTypography } from '../../../styling/generalStyling';
import CommunityCallDetails from '../../../pages/NewCallDetails/communityCallDetails';
import useSelectProfileData from '../../../hooks/useSelectProfileData';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string | undefined;
}
interface Props {
  requested: Array<any>;
  interests: Array<number>;
  communityRequests: Array<any>;
  groupEventRequests?: GroupEventRequest[];
  onDeclineGroupEventRequest: () => void;
  timeZoneString?: string;
  subTab?: string;
}

let display = 0;
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  if (value === 'community-requests') display = 1;
  else if (value === 'my-requests') display = 0;

  return (
    <div
      role="tabpanel"
      hidden={display !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {display === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const RequestsTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'primary',
  },
});

const Requests = ({
  requested,
  interests,
  communityRequests,
  groupEventRequests,
  onDeclineGroupEventRequest,
  timeZoneString = '',
  subTab,
}: Props) => {
  let history = useNavigate();
  const accessToken = getAccessToken();
  if (accessToken === undefined) {
    loginWithRefreshToken();
  }
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;
  const [counter, setCounter] = useState(4);
  const [communityCounter, setCommunityCounter] = useState(4);
  const { toggles } = useSystemTogglesContext();
  const [displayEventDetails, setDisplayEventDetails] = useState(null);
  const [displayCommunityEventDetails, setDisplayCommunityEventDetails] =
    useState(null);
  const [hideGroupEvents, setHideGroupEvents] = useState<boolean>(true);
  const { volunteerProfile, hasFinishedFetchingProfileAndLocation } =
    useSelectProfileData();
  useEffect(() => {
    if (
      hasFinishedFetchingProfileAndLocation &&
      (volunteerProfile?.partner === null ||
        volunteerProfile?.partnerInfo?.hide_group_events === false)
    ) {
      setHideGroupEvents(false);
    }
  }, [hasFinishedFetchingProfileAndLocation, volunteerProfile]);

  if (displayEventDetails === null) Cookie.remove('timeSlotId');
  const communityRequestsToggle = toggles['COMMUNITY_REQUESTS'];
  const [displayedRequests, setDisplayedRequests] =
    useState<any[]>(communityRequests);

  const [checked, setChecked] = React.useState<number[]>([]);
  const [skillsList, setSkillsList] = React.useState<Taxonomy[]>([]);
  useEffect(() => {
    var currentSkillsList: Taxonomy[] = [];
    const getSkillsList = async () => {
      try {
        const response = await api.get<Taxonomies>(
          `api/taxonomy/interests/?limit=1000`,
          {}
        );
        const subCategories = response.data.results.filter(
          (value) => value.parent !== null
        );
        interests.forEach((skillId) => {
          const skill = subCategories.filter(
            (value) => value.id === skillId
          )[0];
          if (skill) currentSkillsList.push(skill);
        });
        if (currentSkillsList) setSkillsList(currentSkillsList);
      } catch (e) {
        console.error(e);
      }
    };
    getSkillsList();
  }, [interests]);
  useEffect(() => {
    const filterRequests = (allRequests: any[]) => {
      const newArray = allRequests.filter((request) => {
        const isFound = checked.find(
          (interestSelected) => interestSelected === request.top_priority.id
        );
        return isFound ? request : null;
      });
      setDisplayedRequests(newArray);
    };
    if (checked.length > 0) filterRequests(communityRequests);
    else setDisplayedRequests(communityRequests);
  }, [checked, communityRequests]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  requested.map((event) => (event['type'] = 'request'));
  const eventsObject = [
    ...requested,
    ...(groupEventRequests && !hideGroupEvents ? groupEventRequests : []),
  ].filter((event) => event.organisation !== null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    history(`/dashboard/requests/${newValue}`);
  };
  const requestsBadge = () => (
    <Badge
      sx={{
        marginLeft: '15px',
        '& .MuiBadge-badge': {
          color: 'white',
          backgroundColor: '#ff5f5f',
        },
      }}
      badgeContent={eventsObject.length}
    />
  );

  const displayEvents = () =>
    eventsObject.slice(0, counter).map((event, index) => {
      if (event.type === 'request') {
        return (
          <NewRequestedCard
            key={event.id}
            type="1To1Request"
            timeZoneString={timeZoneString}
            appointment={event}
            setDisplayEventDetails={setDisplayEventDetails}
          />
        );
      } else if (!hideGroupEvents) {
        return (
          <NewInviteCard
            inviteData={event}
            onDecline={onDeclineGroupEventRequest}
          />
        );
      } else return null;
    });
  const displayCommunityRequests = () =>
    displayedRequests
      .slice(0, communityCounter)
      .filter((event) => event.organisation !== null)
      .map((event, index) => (
        <NewRequestedCard
          key={event.id}
          type="communityRequests"
          timeZoneString={timeZoneString}
          appointment={event}
          setDisplayEventDetails={setDisplayCommunityEventDetails}
        />
      ));
  if (displayEventDetails)
    return <NewCallDetails event={displayEventDetails} />;
  else if (displayCommunityEventDetails)
    return (
      <CommunityCallDetails
        event={displayCommunityEventDetails}
        setDisplayCommunityEventDetails={setDisplayCommunityEventDetails}
      />
    );
  else
    return (
      <div className="dashboard-requests-container">
        <div className="dashboard-title-component">Requests</div>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <RequestsTabs
              value={subTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={
                  !isMobile ? (
                    <CustomRequestsLabel>
                      My Requests {requestsBadge()}
                    </CustomRequestsLabel>
                  ) : (
                    <CustomMobileRequestsLabel>
                      My Requests {requestsBadge()}
                    </CustomMobileRequestsLabel>
                  )
                }
                value={'my-requests'}
              />
              {communityRequestsToggle ? (
                <Tab
                  label={
                    !isMobile ? (
                      <CustomRequestsLabel>
                        Community Requests
                      </CustomRequestsLabel>
                    ) : (
                      <CustomMobileRequestsLabel>
                        Community Requests
                      </CustomMobileRequestsLabel>
                    )
                  }
                  value={'community-requests'}
                />
              ) : null}
            </RequestsTabs>
          </Box>
          <TabPanel value={subTab} index={0} key={0}>
            <div>
              {eventsObject.length > 0 ? (
                <div className="tab-panel-requests-container">
                  {displayEvents()}
                  {counter < eventsObject.length ? (
                    <div className="loading-button-container">
                      <LoadMoreButton
                        variant="contained"
                        onClick={() => setCounter(counter + 4)}
                      >
                        Load more
                      </LoadMoreButton>
                    </div>
                  ) : null}
                </div>
              ) : (
                <RequestsEmptyPage />
              )}
            </div>
          </TabPanel>
          <TabPanel value={subTab} index={1} key={1}>
            <div>
              <div className="community-requests-helper-text">
                Open requests from community members who still need 1:1 help
                <IconButton
                  color="primary"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{ textTransform: 'none', fontSize: '16px' }}
                >
                  <FilterAltOutlinedIcon />
                  Filter
                </IconButton>
              </div>
              {skillsList.length > 0 ? (
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <div className="filter-menu-title">Your skills</div>
                  <List sx={{ padding: 0 }}>
                    {skillsList.map((label, value) => {
                      const labelId = `checkbox-list-label-${label.id}`;

                      return (
                        <ListItem
                          sx={{ marginBottom: '-15px', padding: 0 }}
                          key={label.id}
                          disablePadding
                        >
                          <ListItemButton
                            sx={{ paddingLeft: 0 }}
                            role={undefined}
                            onClick={handleToggle(label.id)}
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                checked={checked.indexOf(label.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={
                                <CheckboxCustomTypography>
                                  {label.name}
                                </CheckboxCustomTypography>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Menu>
              ) : null}
              {communityRequests.length > 0 ? (
                <div className="tab-panel-requests-container">
                  {eventsObject.length > 0 ? (
                    <Alert
                      severity="warning"
                      sx={{ fontFamily: 'Gilroy', maxWidth: '80vw' }}
                    >
                      Please respond to existing opportunities in the My
                      Requests tab first{' '}
                    </Alert>
                  ) : null}
                  <Alert
                    severity="info"
                    sx={{ fontFamily: 'Gilroy', maxWidth: '80vw' }}
                  >
                    Want to help but can’t make any of the proposed times? Click ‘View Details’ and you’ll have the option to message and arrange a time with the mentee.🙂
                  </Alert>
                  {displayCommunityRequests()}
                  {communityCounter < displayedRequests.length ? (
                    <div className="loading-button-container">
                      <LoadMoreButton
                        variant="contained"
                        onClick={() =>
                          setCommunityCounter(communityCounter + 4)
                        }
                      >
                        Load more
                      </LoadMoreButton>
                    </div>
                  ) : null}
                </div>
              ) : (
                <CommunityRequestsEmptyPage />
              )}
            </div>
          </TabPanel>
        </Box>
      </div>
    );
};

export default memo(Requests);
