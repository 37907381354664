import React, { useState } from 'react';
import MessageCenterBlock from './MessageCenterBlock';
import MessageCenterComposer from './MessageCenterComposer';
import MessageCenterHeader from './MessageCenterHeader';
import MessagesContainer from './MessagesContainer';

import './style.scss';
import MessagingRebookModal from '../../../components/MessagingRebookModal';
import MenteeDetailsModal from '../../NewCallDetails/menteeDetailsModal';

interface MessageCenterProps {
  info: any;
  accessPaused: boolean | undefined;
  clickBlock?: () => void;
  refetchMessage: () => void;
  sendMessage: (msg: string) => void;
  isMobile: boolean;
  clickBack: () => void;
  handleGetNextMessages: () => void;
  messages: any;
  hasNextMessages: boolean;
}

const MessageCenter = ({
  refetchMessage,
  info,
  accessPaused,
  clickBlock,
  sendMessage,
  isMobile,
  clickBack,
  handleGetNextMessages,
  messages,
  hasNextMessages,
}: MessageCenterProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rebookModal, setRebookModal] = useState(false);

  return (
    <>
      <div className="message-center-container">
        <MessageCenterHeader
          info={info}
          clickBlock={clickBlock && clickBlock}
          blocked={info?.is_volunteer_active}
          isMobile={isMobile}
          clickBack={clickBack}
          clickRebook={() => setRebookModal(true)}
          clickImage={() => setIsModalOpen(true)}
        />
        <MessagesContainer
          messages={messages}
          hasNextMessages={hasNextMessages}
          handleGetNextMessages={handleGetNextMessages}
        />
        {info?.is_volunteer_active ||
        info?.is_organisation_active ||
        accessPaused ? (
          <MessageCenterBlock
            blockedByVol={info?.is_volunteer_active}
            blockedByMentee={info?.is_organisation_active || accessPaused}
            clickUnblock={clickBlock && clickBlock}
          />
        ) : (
          <MessageCenterComposer
            sendMessage={sendMessage}
            refetchMessage={refetchMessage}
            isMobile={isMobile}
            organisationId={info.organisation.id}
          />
        )}

        <MessagingRebookModal
          visible={rebookModal}
          onClose={() => setRebookModal(false)}
          volunteerProfileId={info?.volunteer?.id}
        />
      </div>

      {isModalOpen ? (
        <MenteeDetailsModal
          isOpen={isModalOpen}
          event={info}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </>
  );
};

export default MessageCenter;
