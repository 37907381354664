import React, { useState } from 'react';
import api from '../../../api';
import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { VolunteerPreferences } from '../types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import DatePicker from 'react-datepicker';
import {
  CustomProfileCancelButton,
  CustomProfileSaveButton,
} from '../../../styling/buttons';
import {
  CustomFormControlLabel,
  CustomFormLabel,
  CustomTypography,
} from '../../../styling/generalStyling';

type Props = {
  data: VolunteerPreferences;
};

const Mentoring = ({ data }: Props) => {
  const [availability] = useState(data.availability);
  const [selectedDays, setSelectedDays] = useState(
    data.availability ? data.availability.days : []
  );
  const [time, setTime] = useState(
    data.availability ? data.availability.minutes : 0
  );
  const days: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const handleSaveClick = async () => {
    const newAvailability = availability
      ? { ...availability }
      : {
          days: [],
          minutes: 0,
          end_hour: 0,
          start_hour: 0,
          end_minute: 0,
          start_minute: 0,
        };
    newAvailability.days = selectedDays;
    newAvailability.minutes = time;
    newAvailability.end_hour = endDateTime.getHours();
    newAvailability.start_hour = startDateTime.getHours();
    newAvailability.end_minute = endDateTime.getMinutes();
    newAvailability.start_minute = startDateTime.getMinutes();
    try {
      await api.patch(`api/volunteer/volunteers/me/`, {
        availability: newAvailability,
      });
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  };

  const today = new Date();
  const inititalStartDateTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    availability ? availability.start_hour : 0,
    availability ? availability.start_minute : 0
  );
  const inititialEndDateTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    availability ? availability.end_hour : 0,
    availability ? availability.end_minute : 0
  );
  const [startDateTime, setStartDateTime] = useState<any>(
    inititalStartDateTime
  );
  const [endDateTime, setEndDateTime] = useState<any>(inititialEndDateTime);
  return (
    <div className="mentoring-preferences__container">
      <div className="mentoring-preferences-title">
        Number of hours you can volunteer
      </div>
      <FormControl>
        <CustomFormLabel id="mentoring-radio-buttons-group-label">
        This lets us know how many requests to send you, but you can mentor as much or as little as you can in a given week.
        </CustomFormLabel>
        <RadioGroup
          aria-labelledby="mentoring-radio-buttons-group-label"
          name="radio-buttons-group"
          value={time}
          sx={{
            '& .Mui-checked': {
              color: '#7FFFD4',
            },
          }}
        >
          <CustomFormControlLabel
            value={60}
            onClick={() => setTime(60)}
            control={<Radio />}
            label={<CustomTypography> 1 hour per week</CustomTypography>}
          />
          <CustomFormControlLabel
            value={120}
            onClick={() => setTime(120)}
            control={<Radio />}
            label={<CustomTypography> 2 hours per week</CustomTypography>}
          />
          <CustomFormControlLabel
            value={180}
            onClick={() => setTime(180)}
            control={<Radio />}
            label={<CustomTypography> 3 hours per week</CustomTypography>}
          />
          <CustomFormControlLabel
            value={240}
            onClick={() => setTime(240)}
            control={<Radio />}
            label={<CustomTypography> 4 hours per week</CustomTypography>}
          />
          <div className="mentoring-custom-hours-input">
            <CustomFormControlLabel
              value={time > 240 ? time : 'null'}
              onClick={() => setTime(3040)}
              control={<Radio />}
              label={<CustomTypography>Custom </CustomTypography>}
            />
            <TextField
              sx={{
                marginLeft: '5px',
                width: '38px',
                '& .MuiInputBase-formControl': {
                  fontFamily: 'Gilroy',
                  fontSize: '15px',
                  justifyContent: 'center',
                },
              }}
              type="number"
              inputProps={{ min: 1, max: 1000 }}
              defaultValue={
                availability && availability.minutes > 240
                  ? availability.minutes / 60
                  : null
              }
              onChange={(event) => setTime(parseInt(event.target.value) * 60)}
              id="standard-basic"
              label=""
              variant="standard"
            />
            <CustomTypography> hours per week</CustomTypography>
          </div>
        </RadioGroup>
      </FormControl>

      <div className="mentoring-preferences-title"> Days you can volunteer</div>
      <FormControl>
        <CustomFormLabel id="gender-radio-buttons-group-label">
          Select the days in week you are happy to volunteer in. We will not
          send you requests beyond these times.
        </CustomFormLabel>
        <FormGroup>
          {days.map((day, index) => (
            <CustomFormControlLabel
              control={
                <Checkbox
                  checked={
                    selectedDays.find(
                      (selectedDay) => selectedDay === index
                    ) !== undefined
                  }
                  onChange={() => {
                    if (selectedDays.indexOf(index) !== -1)
                      setSelectedDays(
                        selectedDays.filter(
                          (selectedDay) => selectedDay !== index
                        )
                      );
                    else
                      setSelectedDays((selectedDays) => [
                        ...selectedDays,
                        index,
                      ]);
                  }}
                  name={day}
                  color="primary"
                />
              }
              label={<CustomTypography> {day}</CustomTypography>}
            />
          ))}
        </FormGroup>
      </FormControl>

      <div className="mentoring-preferences-title">
        {' '}
        Select the time range you are happy to accept mentoring requests between
      </div>
      <div className="datePicker__container">
        <div className="datePicker-time">
          <CustomTypography>Start Time</CustomTypography>
          <DatePicker
            className="datePicker"
            selected={startDateTime}
            onChange={(date) => setStartDateTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
          />
        </div>
        <div className="datePicker-time">
          <CustomTypography>End Time</CustomTypography>
          <DatePicker
            className="datePicker"
            selected={endDateTime}
            onChange={(date) => setEndDateTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
          />
        </div>
      </div>
      <div className="update-preferences-buttons">
        <CustomProfileSaveButton
          variant="contained"
          disabled={selectedDays.length === 0 || time === 3040}
          onClick={() => handleSaveClick()}
        >
          Save
        </CustomProfileSaveButton>
        <CustomProfileCancelButton
          variant="outlined"
          onClick={() => window.location.reload()}
        >
          Cancel
        </CustomProfileCancelButton>
      </div>
    </div>
  );
};

export default Mentoring;
