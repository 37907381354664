import { styled } from '@mui/system';
import { Box, Button, TextField } from '@mui/material';

const breakpoints = {
  mobile: '750px',
  tablet: '900px',
  screen: '1200px',
};

export const CustomProfileSaveButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  backgroundColor: 'primary',
  padding: ' 12px 24px',
  marginTop: '20px',
  marginRight: '30px',
  borderRadius: '32px',
  textTransform: 'none',
  width: '149px',
  height: '44px',
  fontSize: '15px',
  fontWeight: 'bold',
  lineHeight: '1.2',
  letterSpacing: '0.17px',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const NoStyleButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  backgroundColor: 'primary',
  borderRadius: '32px',
  textTransform: 'none',
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const CustomProfileCancelButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  backgroundColor: 'primary',
  width: '149px',
  height: '44px',
  marginTop: '20px',
  marginRight: '10px',
  borderColor: 'primary',
  border: 'solid 2px primary',
  padding: '8px 50px',
  borderRadius: '110px',
  textTransform: 'none',
  fontSize: '15px',
  fontWeight: 'bold',
  lineHeight: '1',
  letterSpacing: '0.51px',
  textAlign: 'center',
  color: '#191e39',
  '&:hover': {
    backgroundColor: 'unset',
    color: theme.palette.primary.main,
  },
}));

export const CustomPreferencesSaveButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  backgroundColor: theme.palette.primary.main,
  padding: ' 12px 24px',
  marginTop: '20px',
  marginRight: '30px',
  borderRadius: '32px',
  textTransform: 'none',
  width: '149px',
  height: '44px',
  fontSize: '15px',
  fontWeight: 'bold',
  lineHeight: '1.2',
  letterSpacing: '0.17px',
  textAlign: 'center',
  color: '#0f0f22',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const CustomPreferencesCancelButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  width: '149px',
  height: '44px',
  marginTop: '20px',
  marginRight: '10px',
  borderColor: '#7FFFD4',
  border: 'solid 2px c2c2c2',
  padding: '8px 50px',
  borderRadius: '110px',
  textTransform: 'none',
  fontSize: '15px',
  fontWeight: 'bold',
  lineHeight: '1',
  letterSpacing: '0.51px',
  textAlign: 'center',
  color: '#191e39',
  '&:hover': {
    backgroundColor: 'unset',
    color: theme.palette.primary.main,
  },
}));

export const DashboardFilledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  backgroundColor: 'primary',
  borderRadius: '32px',
  textTransform: 'none',
  width: '100%',
  height: '44px',
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '0',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const LoginSignUpButtons = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  textTransform: 'none',
  width: '100%',
  height: '44px',
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '0',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '&:disabled': {
    backgroundColor: '#eaeaea',
    color: '#A9A9A9',
  },
}));

export const SkillButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: '1px',
  color: theme.palette.primary.contrastText,
  marginRight: '5px',
  paddingLeft: '10px',
  paddingRight: '10px',
  textTransform: 'none',
  fontFamily: 'Gilroy',
  fontSize: '14px',
  textAlign: 'left',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const ManageCallButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: ' 280px',
  marginTop: '8px',

  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  borderRadius: '110px',
  border: ' solid 1px primary',
  fontFamily: 'Gilroy',
  fontSize: '14px',
  fontWeight: 'bold',
  textAlign: 'center',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const DashboardEmptyButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  textTransform: 'none',
  minWidth: '100px',
  height: '44px',
  color: 'black',
  fontSize: '16px',
  borderRadius: '32px',
  lineHeight: '1',
  letterSpacing: '0.51px',
  fontWeight: 'bold',
  textAlign: 'center',

  '&:hover': {
    backgroundColor: 'unset',
    color: theme.palette.primary.main,
  },
}));

export const DatePopperButton = styled(Button)({
  width: '240px',
  height: '46px',
  margin: '4px 0 0',
  borderRadius: ' 8px',
  border: 'solid 1.2px #333',
  backgroundColor: '#fff',
  fontFamily: 'Gilroy',
  textTransform: 'none',
  fontSize: '14px',
  color: '#414141',
  textAlign: 'left',
});

export const TimeSlotButton = styled(Button)(({ theme }) => ({
  margin: '0px 8px 0 0',
  border: 'solid 1px #777',
  fontFamily: 'Gilroy',
  padding: ' 12px',
  borderRadius: '8px',
  textTransform: 'none',
  width: '129px',
  height: '38px',
  fontSize: '14px',
  textAlign: 'center',
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));
export const MessagingCentreButton = styled(Button)(({ theme }) => ({
  padding: '12px 24px',
  borderRadius: '110px',
  border: 'none',
  backgroundColor: theme.palette.primary.main,

  fontFamily: 'Gilroy',
  fontSize: '17px',
  fontWeight: 'bold',
  lineHeight: '1.2',
  letterSpacing: '0.17px',
  color: theme.palette.primary.contrastText,
  textTransform: 'unset',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  '&:disabled': {
    opacity: '0.4',
    cursor: 'not-allowed',
    color: theme.palette.primary.contrastText,
  },
}));
export const MessagingSendButton = styled(Button)(({ theme }) => ({
  width: '110px',
  height: '46px',
  borderRadius: '60px',
  backgroundColor: theme.palette.primary.main,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },

  '&:disabled': {
    backgroundColor: '#e0e0e0',
  },
}));

export const StyledDownloadButton = styled(Box)(({ theme }) => ({
  width: '20px',
  height: '20px',
  WebkitMask: 'url("download-icon.svg") no-repeat center',
  mask: 'url("download-icon.svg") no-repeat center',
  backgroundColor: '#999999',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const StyledTrashButton = styled(Box)(({ theme }) => ({
  width: '20px',
  height: '20px',
  WebkitMask: 'url("trash-icon.svg") no-repeat center',
  mask: 'url("trash-icon.svg") no-repeat center',
  backgroundColor: '#999999',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  [`@media (max-width: ${breakpoints.mobile})`]: {
    width: '20px',
    height: '20px',
  },
}));
export const AttachmentButton = styled(Box)(({ theme }) => ({
  width: '20px',
  height: '20px',
  marginRight: '15px',
  WebkitMask: 'url("attachment.svg") no-repeat center',
  mask: 'url("attachment.svg") no-repeat center',
  backgroundColor: '#999999',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const CardTextField = styled(TextField)({
  fontFamily: 'Gilroy',
  backgroundColor: 'white',
  borderRadius: '10px',
  border: '1px solid #E8E8E8',

  '& .MuiOutlinedInput-root': {
    fontFamily: 'Gilroy',
    '& input': {
      height: '15px',
      color: 'black',
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    '& fieldset': {
      border: 'none',
      color: 'black',
      borderRadius: '10px',
    },
  },
});

export const CardButton = styled(Button)({
  fontFamily: 'Gilroy',
  width: '190px',
  height: '15px',
  padding: '10px 20px',
  fontSize: '16px',
  color: 'black',
  backgroundColor: 'white',
  borderRadius: '10px',
  border: '1px solid #E8E8E8',
  '&:hover': {
    backgroundColor: '#FADCD9',
  },
  '&:active': {
    backgroundColor: '#F7C1C1',
  },
});

export const AvailabilityActionPlanButton = styled(Button)({
  width: '100%',
  height: '50px',
  borderRadius: '10px',
  border: '1px solid #E8E8E8',
  backgroundColor: '#fff',
  fontFamily: 'Gilroy',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#fff',
    border: '1px solid black',
  },
});