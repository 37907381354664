import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import { searchMessages } from '../../../api/messagingCentre';
import MessageActionButton from '../MessageActionButton';

import './style.scss';
import { StyledBadge } from '../../../styling/generalStyling';

interface MessagePreviewProps {
  id: number;
  clickBlock?: () => void;
  profilePicture?: string;
  firstName: string;
  lastMessageText?: string;
  lastMessageTime?: string;
  clickMessage: () => void;
  isSelected?: boolean;
  blocked: boolean;
  unreadMessages: number;
}

const MessagePreview = ({
  id,
  clickBlock,
  profilePicture,
  firstName,
  lastMessageText,
  lastMessageTime,
  clickMessage,
  isSelected,
  blocked,
  unreadMessages,
}: MessagePreviewProps) => {
  const [lastMsg, setLastMsg] = useState<any>(undefined);

  useEffect(() => {
    async function test() {
      const res = await searchMessages({ organisationId: id });
      const sortedRes = res?.data?.results?.sort(
        (m1: any, m2: any) =>
          new Date(m2.created).getTime() - new Date(m1.created).getTime()
      );
      const lastMsg = sortedRes?.[0];
      setLastMsg(lastMsg);
    }

    test();
  }, [id]);

  const isWhitelabel = !!Cookie.get('partner_id');

  return (
    <div
      className={`message-preview-container ${
        isWhitelabel && 'message-preview-whitelabel'
      } ${
        isSelected && !isWhitelabel
          ? 'message-preview-selected'
          : isSelected && isWhitelabel && 'message-preview-whitelabel-selected'
      }`}
      onClick={clickMessage}
    >
      <div className="message-preview-photo-wrapper">
        <img
          className="message-preview-photo"
          src={profilePicture || 'avatar.png'}
          alt="User"
        />

        <div className="message-preview-info-wrapper">
          <div className="message-preview-name">
            <StyledBadge
              badgeContent={unreadMessages}
              color="info"
              sx={{ width: 'fit-content' }}
            >
              <div className="message-preview-name-text">{firstName}</div>
            </StyledBadge>
          </div>
          <div className="message-preview-preview">
            {lastMsg?.message || 'No recent messages...'}
          </div>
          <div className="message-preview-time">
            {lastMsg && moment(lastMsg?.created).format('D MMMM HH:mm')}
          </div>
        </div>
      </div>

      <MessageActionButton
        clickBlock={clickBlock && clickBlock}
        blocked={blocked}
      />
    </div>
  );
};

export default MessagePreview;
