import React, { memo } from 'react';
import { BarChart, Bar, XAxis, Tooltip } from 'recharts';

import useWindowSize from '../../../hooks/useWindowSize';
import moment from 'moment';
import { CustomPaper } from '../../../styling/backgrounds';
import { roundToNextWhole } from '../../../utils/text';

interface Props {
  totalSessions: any[];
  orgsHelped: number;
}
const DashboardSessionsChart = ({ totalSessions, orgsHelped }: Props) => {
  const data = [
    {
      name: 'J',
      sessions: 0,
      month: 1,
    },
    {
      name: 'F',
      sessions: 0,
      month: 2,
    },
    {
      name: 'M',
      sessions: 0,
      month: 3,
    },
    {
      name: 'A',
      sessions: 0,
      month: 4,
    },
    {
      name: 'M',
      sessions: 0,
      month: 5,
    },
    {
      name: 'J',
      sessions: 0,
      month: 6,
    },
    {
      name: 'J',
      sessions: 0,
      month: 7,
    },
    {
      name: 'A',
      sessions: 0,
      month: 8,
    },
    {
      name: 'S',
      sessions: 0,
      month: 9,
    },
    {
      name: 'O',
      sessions: 0,
      month: 10,
    },
    {
      name: 'N',
      sessions: 0,
      month: 11,
    },
    {
      name: 'D',
      sessions: 0,
      month: 12,
    },
  ];

  const minutesMentored = totalSessions.reduce(
    (n: any, { duration }: any) => n + duration,
    0
  );
  totalSessions.forEach(function (item, index) {
    var currentyYear = moment().year();
    var result = data.find(
      (dataEntry) =>
        dataEntry.month === item.month && item.year === currentyYear
    );
    if (result) result.sessions = roundToNextWhole(item.duration / 60);
  });
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;
  const d = new Date();
  let month = d.getMonth() + 1;
  const sessionsThisMonth = data.find(
    (dataEntry) => dataEntry.month === month
  )?.sessions;
  return (
    <div className="dashboard-impact-statistics-box">
      <div>
        <CustomPaper
          elevation={0}
          sx={{
            width: '334px',
            height: '220px',
            margin: '12px 80px 8px 24px',
            padding: '16px 79px 17px 21px',
          }}
        >
          <div className="dashboard-tootltip-statistics">
            <BarChart
              width={isMobile ? 250 : 317}
              height={150}
              data={data}
              margin={{
                top: 15,
                right: isMobile ? 10 : 30,
                left: isMobile ? 0 : 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="name" />

              <Tooltip />
              <Bar
                dataKey="sessions"
                fill="#b2d5ef"
                background={{ fill: 'none' }}
              />
            </BarChart>
            <div className="dashboard-chart-bold-text">
              {' '}
              {sessionsThisMonth?roundToNextWhole(sessionsThisMonth):0} sessions this month
            </div>
          </div>
        </CustomPaper>
      </div>

      <div className="dashboard-impact-statistics">
        <div>
          <CustomPaper
            elevation={0}
            sx={{
              width: '158px',
              height: '180px',
              margin: '8px 8px 32px 19px',
              padding: '52px 26px 51px 24px',
            }}
          >
            <div className="dashboard-tootltip-statistics">
              <div className="dashboard-chart-text">
                {orgsHelped ? orgsHelped : 0}
              </div>
              <div className="dashboard-chart-bold-text">
                <div>Organisations helped</div>
              </div>
              <div className="chart-text-helper">Since joined</div>
            </div>
          </CustomPaper>
        </div>
        <div>
          <CustomPaper
            elevation={0}
            sx={{
              width: '158px',
              height: '180px',
              margin: '8px 8px 32px 19px',
              padding: '52px 26px 51px 24px',
            }}
          >
            <div className="dashboard-tootltip-statistics">
              <div className="dashboard-chart-text">{minutesMentored} min</div>
              <div className="dashboard-chart-bold-text">Minutes mentored</div>
              <div className="chart-text-helper">Since joined</div>
            </div>
          </CustomPaper>
        </div>
      </div>
    </div>
  );
};

export default memo(DashboardSessionsChart);
